<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/"
                       class="d-flex align-center">
            <v-img :src="appLogo"
                   max-height="30px"
                   max-width="30px"
                   alt="logo"
                   contain
                   class="me-3 "></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text v-if="failed">
          <v-alert text
                   color="error">
            <small class="d-block mb-1">
              Invalid email address / password.
            </small>
          </v-alert>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
            <v-text-field v-model="email"
                          outlined
                          label="Email"
                          placeholder="john@example.com"
                          hide-details
                          class="mb-3"></v-text-field>

            <v-text-field v-model="password"
                          outlined
                          :type="isPasswordVisible ? 'text' : 'password'"
                          label="Password"
                          placeholder="············"
                          :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                          hide-details
                          @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Remember Me"
                          hide-details
                          class="me-3 mt-1">
              </v-checkbox>

              <!-- forgot link -->
              <router-link :to="{name:'auth-forgot-password'}"
                           class="mt-1">
                Forgot Password?
              </router-link>
            </div>

            <v-btn block
                   type="submit"
                   color="primary"
                   class="mt-6">
              Login
            </v-btn>
          </v-form>
        </v-card-text>

      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img class="auth-mask-bg"
         height="173"
         :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)">

  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
  import { ref, getCurrentInstance } from '@vue/composition-api'
  import themeConfig from '@themeConfig'
  import { useRouter } from '@core/utils'

  export default {
    setup() {
      const vm = getCurrentInstance().proxy
      const { router } = useRouter()

      const loginForm = ref(null)
      const isPasswordVisible = ref(false)
      const email = ref('')
      const password = ref('')
      const errorMessages = ref([])
      const failed = ref(false)

      const handleFormSubmit = () => {
        const isFormValid = loginForm.value.validate()
        failed.value = false

        if (!isFormValid) return

        /*
          1. Make HTTP request to get accessToken
          2. Store received token in localStorage for future use
          3. Make another HTTP request for getting user information
          4. On successful response of user information redirect to home page

          ? We have use promise chaining to get user data from access token
          ? Promise Chaining: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#chaining
        */

        vm.$http
          .post(vm.$store.state.apiUrl + '/auth/login', { email: email.value, password: password.value })
          .then(response => {
            const accessToken = response.data
            // ? Set access token in localStorage so axios interceptor can use it Axios Interceptors: https://github.com/axios/axios#interceptors
            localStorage.setItem('accessToken', accessToken)
            return response
          })
          .then(() => {
            vm.$http.get(vm.$store.state.apiUrl + '/auth/me').then(response => {
              const user = response.data
              const userAbility = user.Ability

              vm.$ability.update(userAbility)

              localStorage.setItem('userAbility', JSON.stringify(userAbility))

              delete user.Ability

              localStorage.setItem('userData', JSON.stringify(user))

              router.push('/')
            })
          })
          .catch(error => {
            vm.failed = true
          })
      }

      return {
        isPasswordVisible,
        email,
        password,
        handleFormSubmit,
        failed,
        errorMessages,

        appName: themeConfig.app.name,
        appLogo: themeConfig.app.logo,

        icons: {
          mdiEyeOutline,
          mdiEyeOffOutline,
        },

        loginForm
      }
    },
  }
</script>

<style lang="scss">
  @import '@core/preset/preset/pages/auth.scss';
</style>
